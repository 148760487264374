.mobile-nav {
  --pt: calc(var(--header-height) + var(--header-top-height));
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--window-width);
  z-index: 999;
  background-color: #08071d;
  padding-top: var(--pt);
  transition: padding-top var(--trs-2), opacity var(--trs-2), visibility var(--trs-2);
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  &__inner {
    min-height: 100%;
    padding: 100px 0;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &.header-is-fixed {
    --pt: var(--header-height);
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &__item {
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__link {
    font-size: 20px;
    transition: color var(--trs-1);
    &[data-touch], &[data-hover] {
      color: #fff;
    }
  }
  &__phone {
    font-size: 20px;
    margin-top: 100px;
    transition: color var(--trs-1);
    &[data-touch], &[data-hover] {
      color: #fff;
    }
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    &__inner {
      padding: 60px 0;
    }
    &__phone {
      margin-top: 60px;
    }
  }
}