.home {
  --pt: calc(var(--header-height) + var(--header-top-height));
  --lamp-size: 417px;
  padding: var(--pt) 0 0 0;
  min-height: var(--window-safe-height);
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  display: flex;
  &__lamp {
    position: absolute;
    z-index: 3;
    left: 0;
    top: calc(var(--header-height) + var(--header-top-height));
    img {
      width: var(--lamp-size);
      height: auto;
      position: relative;
      transition: opacity var(--trs-3), visibility var(--trs-3);
      &:last-child {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
      }
    }
    &.active {
      img {
        &:first-child {
          opacity: 0;
          visibility: hidden;
        }
        &:last-child {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    @supports (filter: blur(250px)) {
      &::after {
        --size: calc(var(--lamp-size) * 0.75);
        content: '';
        display: block;
        width: var(--size);
        height: var(--size);
        border-radius: 100%;
        position: absolute;
        bottom: 11%;
        right: 13%;
        background-color: #D2476F;
        filter: blur(250px);
        opacity: 0;
        transition: opacity var(--trs-3);
        will-change: opacity;
      }
      &.active {
        &::after {
          opacity: 1;
        }
      }
    }
  }
  &__light {
    --size: calc(var(--lamp-size) * 0.75);
    width: var(--size);
    height: var(--size);
    border-radius: 100%;
    background-color: #323070;
    position: absolute;
    top: 168px;
    left: 50px;
    box-shadow: 0px 0px 200px 200px #323070;
    will-change: opacity;
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.04;
    z-index: 2;
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    z-index: 4;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  &__content {
    max-width: 570px;
  }
  &__title {
    margin-top: 0;
  }
  &__text {
    font-size: 20px;
    color: #fff;
    margin: 0;
  }
  &__logo {
    width: 108px;
    margin-bottom: 12px;
  }
  &__button {
    margin-top: 40px;
    border-radius: 4px;
    height: 66px;
    font-weight: 500;
    font-size: 20px;
    width: 280px;
  }
  @include media-breakpoint-down(lg) {
    --lamp-size: 300px;
    &__content {
      max-width: 480px;
    }
    &__container {
      padding-top: 300px;
    }
    &__light {
      top: 155px;
      left: 39px;
    }
  }
  @include media-breakpoint-down(md) {
    &__content {
      max-width: initial;
      max-width: 400px;
    }
  }
  @include media-breakpoint-down(sm) {
    --lamp-size: 270px;
    &__light {
      top: 129px;
      left: 32px;
    }
    &__logo {
      margin-bottom: 15px;
    }
    &__content {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: initial;
      width: 100%;
    }
    &__container {
      padding-bottom: 60px;
      padding-top: 310px;
    }
    &__button {
      width: 100%;
    }
  }
}

.message-label-section {
  background-color: var(--color-accent);
  padding: 26px 0;
  margin-bottom: calc(var(--section-margin) / 2);
  &__text {
    color: var(--color-text-dark);
    text-align: center;
  }
  @include media-breakpoint-down(sm) {
    padding: 15px 0;
  }
}

.about {
  &__text {
    font-size: 20px;
    strong {
      font-weight: inherit;
      color: #fff;
    }
  }
  &__image {
    border-radius: 8px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    height: 290px;
  }
  @include media-breakpoint-down(sm) {
    &__image {
      height: 190px;
    }
  }
}

.services {
  &__container {
    display: grid;
    gap: var(--grid-gutter-width);
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
  }
  @include media-breakpoint-down(lg) {
    &__container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @include media-breakpoint-down(sm) {
    &__container {
      grid-auto-rows: auto;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.services-block {
  --radius: 8px;
  border-radius: var(--radius);
  overflow: hidden;
  background-color: #211E65;
  height: 100%;
  box-shadow: 0px 4px 20px rgba(14, 23, 54, 0.2);
  &__image {
    height: 200px;
    border-radius: var(--radius) var(--radius) 0 0;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
  &__content {
    padding: 20px;
    font-size: 14px;
  }
  @include media-breakpoint-down(md) {
    &__image {
      height: 160px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__image {
      height: 200px;
    }
  }
}

.section-meter {
  margin-bottom: calc(var(--section-margin) / 2);
}

.meter-box {
  --radius: 8px;
  background-color: #211E65;
  border-radius: var(--radius);
  position: relative;
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 100px 90px;
    gap: 120px;
  }
  &__image {
    width: 214px;
    flex: 0 0 auto;
    img {
      width: 100%;
      filter: drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.1)) drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.25)) drop-shadow(-7px 12px 30px rgba(0, 0, 0, 0.25));
    }
  }
  &__text {
    font-size: 28px;
  }
  @include media-breakpoint-up(md) {
    &__image {
      flex: 0 0 auto;
    }
    &__content {
      flex: 1 1 auto;
    }
  }
  @include media-breakpoint-down(lg) {
    &__container {
      padding: 60px 40px;
      gap: 40px;
    }
  }
  @include media-breakpoint-down(md) {
    &__container {
      flex-direction: column;
    }
    &__image {
      order: 2;
    }
    &__text {
      font-size: 22px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__text {
      font-size: 16px;
    }
    &__container {
      padding: 20px;
      gap: 30px;
    }
    &__image {
      width: 100%;
      max-width: 290px;
    }
  }
}

.partners {
  margin-bottom: calc(var(--section-margin) / 2);
  padding: var(--section-margin) 0;
  background-color: #211E65;
  &__container {
    display: grid;
    flex-wrap: wrap;
    gap: 50px 30px;
    grid-template-columns: repeat(4, 1fr);
  }
  &__item {
    position: relative;
    .image {
      transition: opacity var(--trs-1);
      will-change: opacity;
      &:last-child {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0; 
      }
    }
    &[data-touch], &[data-hover] {
      .image {
        &:first-child {
          transition-delay: var(--animation-duration-1);
          opacity: 0;
        }
        &:last-child {
          opacity: 1;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    &__container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @include media-breakpoint-down(sm) {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px 0;
    }
    &__item {
      &:nth-child(1) {
        width: 170px;
      }
      &:nth-child(2) {
        width: 200px;
      }
      &:nth-child(3) {
        width: 200px;
      }
      &:nth-child(4) {
        width: 160px;
      }
    }
  }
}

.form-block {
  background-color: #211E65;
  border-radius: 8px;
  position: relative;
  padding: 60px 90px 60px 370px;
  margin-bottom: calc(var(--section-margin) / 2);
  &__head {
    margin-bottom: 20px;
  }
  &__image {
    position: absolute;
    bottom: 0;
    left: 60px;
    width: 260px;
  }
  @include media-breakpoint-down(lg) {
    padding: 60px 60px 60px 370px;
    &__image {
      left: 40px;
      width: 290px;
    }
  }
  @include media-breakpoint-down(md) {
    padding: 60px 60px 400px 60px;
    margin-bottom: 0;
    &__image {
      left: 50%;
      transform: translateX(-50%);
      width: 220px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 40px 20px 360px 20px;
    &__image {
      width: 208px;
    }
    &__text {
      font-size: 14px;
    }
  }
}

.contacts {
  &__map {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    iframe {
      display: block;
      border: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  &__content {
    background: #FFFFFF;
    color: var(--color-text-dark);
    box-shadow: 0px 4px 20px rgba(14, 23, 54, 0.2);
    border-radius: 8px;
    width: 470px;
    margin: 0 var(--content-fields-width) 0 auto;
    padding: 60px;
    position: relative;
    z-index: 2;
  }
  &__title {
    color: inherit;
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
  &__element {
    position: relative;
    padding-left: 34px;
    transition: opacity var(--trs-1);
    .icon {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &[data-touch], &[data-hover] {
      opacity: 0.5;
    }
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    padding: 60px 0;
    background-color: #211E65;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: calc(var(--section-margin) / 2);
    &__content {
      margin: 0 auto 20px auto;
      width: var(--content-width);
    }
    &__map {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      width: var(--content-width);
      margin: 0 auto;
      height: 300px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__content {
      padding: 20px;
    }
  }
}