.header {
  position: fixed;
  width: var(--page-width);
  top: 0;
  z-index: 1000;
  transition: transform var(--trs-2);
  &_fixed {
    transform: translateY(calc(var(--header-top-height) * -1));
  }
  /* &_hidden {
    transform: translateY(calc((var(--header-top-height) + var(--header-height)) * -1));
  } */
}

.header-top {
  background-color: rgba(0, 0, 0, 0.95);
  &__container {
    height: var(--header-top-height);
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;
    gap: 15px;
  }
  &__left {
    display: flex;
  }
  &__center {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
  &__item {
    display: flex;
    --icon-color: rgba(255, 255, 255, 0.5);
    align-items: center;
    gap: 6px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    line-height: 18px;
    transition: color var(--trs-1);
    .icon {
      display: block;
      width: 18px;
      height: 18px;
      color: rgba(255, 255, 255, 0.5);
      transition: inherit;
    }
    &[data-touch], &[data-hover], .active & {
      color: #fff;
      .icon {
        color: var(--icon-color);
      }
    }
    &_location {
      --icon-color: #F7CF00;
    }
    &_skype {
      --icon-color: #48AEEA;
    }
    &_viber {
      --icon-color: #76559B;
    }
    &_whatsapp {
      --icon-color: #79E571;
    }
    &_phone {
      --icon-color: #fff;
    }
    &_telegram {
      --icon-color: #4CA2DC;
    }
  }
  @include media-breakpoint-up(lg) {
    &__item {
      &_phone {
        order: 3;
        .icon {
          display: none;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &__container {
      display: flex;
    }
    &__center {
      order: 3;
      flex: 0 0 auto;
    }
    &__right {
      flex: 1 1 auto;
    }
    &__item {
      &_skype, &_telegram {
        span {
          display: none;
        }
      }
      &_phone {
        span {
          display: none;
        }
      }
    }
  }
}

.header-main {
  background-color: rgba(8, 7, 29, 0.95);
  position: static;
  &__container {
    display: grid;
    align-items: center;
    height: var(--header-height);
    grid-template-columns: 1fr auto 1fr;
  }
  &__phone {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    color: #fff;
  }
  @include media-breakpoint-down(lg) {
    &__phone {
      display: none;
    }
    &__container {
      display: flex;
      justify-content: space-between;
    }
  }
}

.header-nav {
  &__list {
    display: flex;
    align-items: center;
    gap: 48px;
  }
  &__link {
    transition: color var(--trs-1);
    &[data-touch], &[data-hover] {
      color: #fff;
    }
  }
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.nav-burger {
  @include media-breakpoint-up(lg) {
    display: none;
  }
  @include media-breakpoint-down(lg) {
    width: 40px;
    height: 40px;
    background-color: var(--bg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 -8px;
    transition: opacity var(--trs-1);
    span {
      margin: 3px 0;
      width: 24px;
      height: 2px;
      background-color: #DADADA;
      transition: transform var(--trs-2), opacity var(--trs-2);
    }
    &.active {
      span {
        &:first-child {
          transform: translateY(8px) rotate(45deg);
        }
        &:nth-child(2) {
          transform: scaleX(0);
          opacity: 0;
        }
        &:last-child {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
    &[data-touch], &[data-hover] {
      opacity: 0.75;
    }
  }
}