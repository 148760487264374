$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px
);

$container-max-widths: (
  sm: 540px,
  md: 768px,
  lg: 992px,
  xl: 1110px
);

$spacers: (
  grid: var(--grid-gutter-width),
  0: 0px,
  5: 5px,
  10: 10px,
  15: 15px,
  20: 20px,
  25: 25px,
  30: 30px,
  35: 35px,
  40: 40px,
  45: 45px,
  50: 50px,
  60: 60px
);

$grid-gutter-width: var(--grid-gutter-width);
$container-padding-x: var(--container-padding-x);
$card-group-margin: $grid-gutter-width;

@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../../node_modules/bootstrap/scss/mixins/container";
@import "../../../../node_modules/bootstrap/scss/mixins/grid";
@import "../../../../node_modules/bootstrap/scss/mixins/utilities";
@import "../../../../node_modules/bootstrap/scss/vendor/rfs";
@import "../../../../node_modules/bootstrap/scss/utilities";
@import "../../../../node_modules/bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "width": map-merge(
      map-get($utilities, "width"),
      ( responsive: true )
    ),
    "text-align": map-merge(
      map-get($utilities, "text-align"),
      ( responsive: true )
    )
  )
);

$utilities: map-get-multiple($utilities, ("display", "order", "flex", "flex-shrink", "flex-direction", "flex-wrap", "justify-content", "align-items", "margin-top", "margin-bottom", "margin-start", "margin-end", "padding-top", "padding-bottom", "padding-start", "padding-end", "font-weight", "text-align", "width"));
