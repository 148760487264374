* {
  box-sizing: border-box;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:before, &:after {
    box-sizing: border-box;
  }
}

button, input, textarea, select {
  outline: none;
  display: block;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  appearance: none;
  padding: 0;
  border-radius: 0;
  color: inherit;
  font-weight: inherit;
  text-rendering: inherit;
  line-height: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input {
  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 10000s;
  }
}

textarea {
  resize: none;
}

a, button, select, label {
  user-select: none;
  -webkit-user-drag: none;
}

button, label {
  cursor: pointer;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

table {
  border-collapse: collapse;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
  user-select: none;
  max-width: 100%;
}