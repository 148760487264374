html, body {
  font-size: 16px;
}

body {
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.75);
  line-height: 1.5;
  text-rendering: geometricPrecision;
}

button, input, textarea {
  text-rendering: inherit;
  font-weight: inherit;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin: 35px 0 20px 0;
  line-height: 1.2;
  color: #fff;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

h1, .h1 {
  font-size: 52px;
  @include media-breakpoint-down(lg) {
    font-size: 44px;
  }
  @include media-breakpoint-down(md) {
    font-size: 36px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 30px;
  }
}
h2, .h2 {
  font-size: 40px;
  @include media-breakpoint-down(md) {
    font-size: 34px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 30px;
  }
}
h3, .h3 {
  font-size: 34px;
  @include media-breakpoint-down(md) {
    font-size: 28px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 26px;
  }
}
h4, .h4 {
  font-size: 28px;
  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}
h5, .h5 {
  font-size: 24px;
  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }
}
h6, .h6 {
  font-size: 20px;
}

p {
  margin: 0 0 1em 0;
  &:last-child {
    margin-bottom: 0;
  }
}

strong, b {
  font-weight: 700;
}

a {
  &:not([class]), &.link {
    color: #fff;
    border-bottom: 1px solid currentColor;
    transition: color var(--trs-1);
    &[data-touch], &[data-hover] {
      color: rgba(255, 255, 255, 0.75);
    }
  }
}